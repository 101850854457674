import "../../../styles/teste.css";
import "../../../styles/navbar.css";
import styledCSS from "../../../styles/module/navbar/navbar.module.css";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";

import { NomePortal, NomeTipoPortal } from "../../../services/variables";
import { useNavigate } from "react-router-dom";

import jsonMenu from "../../jsons/jsonNavbarCM";
import { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";

import JoinButtonsTopNavbar from "./utils/iconeSuspended/JoinButtons";

export default function NavbarCM() {
  const [statusNavbarleft, setStatusNavbarleft] = useState(false);
  const [increase, setIncrease] = useState(0);
  const [decrease, setDecrease] = useState(0);
  const [fontNormal, setFontNormal] = useState(0);
  const [fontSize2, setFontSize] = useState(100);
  const [scrollX, setScrollx] = useState(0);
  const [scrollAuto, setScrollAuto] = useState(true);
  const [count, setCount] = useState(0);
  const [show, setShow] = useState(false);
  const handleCloseDrop = () => setShow(false);

  const [lengthItens] = useState(jsonMenu.length * 220);

  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const selectOpen = (e) => {
    e.preventDefault();
    navigate(`/pesquisar/${search}/`);
  };

  const arrayMap = jsonMenu.map(
    (
      itens,
      indexArrayMap //vai percorrer o array e vai retornar os elementos da lista
    ) => sumList(itens, indexArrayMap)
  );
  function sumList(array, index) {
    //vai aplicar o jsx
    if (
      array.top_list !== undefined &&
      array.top_list == true &&
      array.sub_link !== undefined
    ) {
      return (
        <>
          <li className={"itens-navbar itens button-navbar"} key={index}>
            <div className="button-navbar-slider">
              <img
                src={`/icons/${array.img}.svg`}
                type="image/svg+xml"
                alt="ícones"
                className="svg svg-navbar"
              />
              {checkLink(array)}
            </div>
            <ul className="list-navbar lista-secundaria-navbar">
              {array.sub_link.map((itens, index) => sumList(itens, index))}
            </ul>
          </li>
        </>
      );
    } else if (
      array.top_list !== undefined &&
      array.top_list == true &&
      array.sub_link == undefined
    ) {
      return (
        <>
          <li className={"itens-navbar itens button-navbar"} key={index}>
            <a href={array.link} className="drop-navbar" target="_blank">
              <div className="button-navbar-slider">
                <img
                  src={`/icons/${array.img}.svg`}
                  type="image/svg+xml"
                  alt="ícones"
                  className="svg svg-navbar"
                />
                {array.name}
              </div>
            </a>
          </li>
        </>
      );
    } else {
      if (array.sub_link !== undefined) {
        // vai verificar se existe uma lista dentro de outra lista

        return (
          <>
            <li className={"itens-navbar itens sub-list"} key={index}>
              {checkLink(array)}
              <ul className="list-navbar lista-secundaria-navbar">
                {array.sub_link.map((itens, index) => sumList(itens, index))}
              </ul>
            </li>
          </>
        );
      } else {
        return (
          <>
            <li className="itens-navbar itens sub-link" key={index}>
              <a href={array.link} className="drop-navbar">
                {array.name}
              </a>
            </li>
          </>
        );
      }
    }
  }
  //vai verificar se existe um link (o elemento pai de uma lista oculta não deve possuir link)
  function checkLink(array) {
    if (array.link !== undefined) {
      return (
        <a href={array.link} className="drop-navbar">
          <p>{array.name}</p>
        </a>
      );
    } else {
      return <p className="icon-drop drop-navbar">{array.name}</p>;
    }
  }
  const sidebar = () => {
    if (statusNavbarleft) {
      setStatusNavbarleft(false);
    } else {
      setStatusNavbarleft(true);
    }
  };

  const list_sub_link = (array) => {
    return (
      <div className="sub-div-sidebar-new">
        {array.map((b, index) => (
          <li key={index} className="desq">
            {b.link !== undefined ? (
              <a href={b.link} className="desq link-sidebar">
                {b.name}
              </a>
            ) : (
              <h6 className="desq">{b.name}</h6>
            )}
            <ul>{b.sub_link !== undefined ? list_sub_link(b.sub_link) : ""}</ul>
          </li>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const elementBody = document.querySelector(":root");
    const elementBtnIncreaseFont = document.getElementById("increase-font");

    // Valor de incremento ou decremento, equivale a 10% do valor do Body
    const increaseDecrease = 10;

    //Evento de click para aumentar a fonte
    const skip = fontSize2 + increaseDecrease;

    setFontSize(Math.abs(skip));
    if (elementBtnIncreaseFont != null) {
      elementBtnIncreaseFont.addEventListener("click", function (event) {
        elementBody.style.fontSize = skip + "%";
      });
    }
    // eslint-disable-next-line
  }, [increase]);
  useEffect(() => {
    const elementBody = document.querySelector(":root");
    const elementBtnDecreaseFont = document.getElementById("decrease-font");
    // Valor de incremento ou decremento, equivale a 10% do valor do Body
    const increaseDecrease = 10;
    if (fontSize2 > 100) {
      const skip = fontSize2 - increaseDecrease;

      setFontSize(Math.abs(skip));
      if (elementBtnDecreaseFont != null) {
        // Evento de click para diminuir a fonte
        elementBtnDecreaseFont.addEventListener("click", function (event) {
          elementBody.style.fontSize = skip + "%";
        });
      }
    }

    // eslint-disable-next-line
  }, [decrease]);
  useEffect(() => {
    const elementBody = document.querySelector(":root");
    const elementBtnDecreaseFont = document.getElementById("normal-font");

    //  Evento de click para voltar ao tamanho normal da fonte
    elementBtnDecreaseFont.addEventListener("click", function (event) {
      setIncrease(0);
      setFontSize(100);
      setDecrease(0);
      elementBody.style.fontSize = 100 + "%";
    });
    // eslint-disable-next-line
  }, [fontNormal]);

  const keyIncrease = () => {
    /// document.getElementById("increase-font").click();
    console.log("aumentar");
  };
  const clickSegment = () => {
    // eslint-disable-next-line
    if (localStorage.getItem("userway-s3") == 0) {
      localStorage.setItem("userway-s3", 1);
      document.location.reload();
    } else {
      localStorage.setItem("userway-s3", 0);
      document.location.reload();
    }
  };
  // arrow slider
  const handleRightArrow = () => {
    setScrollAuto(false);

    var x = scrollX + 220;

    //nº de itens - (Tela * 4)

    if (x < 0) {
      setCount(count - 1);
    } else {
      x = 0;
    }
    setScrollx(x);
    //console.log(x)
    //console.log("voltar scroll "+scrollX+ " Count:"+ count + " Lista" +lengthItens +" Tela "+ Math.round(window.innerWidth))
  };
  const handleLeftArrow = () => {
    setScrollAuto(false);

    const x = scrollX - 220;

    //nº de itens - (Tela * 4)

    if (Math.round(window.innerWidth) <= 845) {
      //telas menores que 400px
      if (x >= 220 * 1 - lengthItens) {
        setCount(count + 1);
        setScrollx(x);
        //console.log("Avançar tela celular, scroll "+scrollX+ " Count:"+ count + " Lista" +lengthItens +" Tela "+ Math.round(window.innerWidth))
      }
    } else if (
      845 < Math.round(window.innerWidth) &&
      Math.round(window.innerWidth) <= 1300
    ) {
      if (x >= 220 * 2 - lengthItens) {
        setCount(count + 1);
        setScrollx(x);
        //console.log("Avançar tela celular, scroll "+scrollX+ " Count:"+ count + " Lista" +lengthItens +" Tela "+ Math.round(window.innerWidth))
      }
    } else if (
      1300 < Math.round(window.innerWidth) &&
      Math.round(window.innerWidth) <= 1400
    ) {
      if (x >= 220 * 3 - lengthItens) {
        setCount(count + 1);
        setScrollx(x);
      }
    } else {
      if (x >= 220 * 4 - lengthItens) {
        setCount(count + 1);
        setScrollx(x);
        console.log(
          "Avançar scroll " +
            scrollX +
            " Count:" +
            count +
            " Lista" +
            lengthItens +
            " Tela " +
            Math.round(window.innerWidth)
        );
      }
    }
  };

  if (Math.round(window.innerWidth) <= 845) {
    //telas menores que 400px
    var lengthButtons = lengthItens - 220 * 1;
    var delayTransition = 100;
  } else if (
    845 < Math.round(window.innerWidth) &&
    Math.round(window.innerWidth) <= 1300
  ) {
    var lengthButtons = lengthItens - 220 * 2;
    var delayTransition = 70;
  } else if (
    1300 < Math.round(window.innerWidth) &&
    Math.round(window.innerWidth) <= 1400
  ) {
    var lengthButtons = lengthItens - 220 * 3;
    var delayTransition = 45;
  } else {
    var lengthButtons = lengthItens - 220 * 4;
    var delayTransition = 35;
  }
  var efect = keyframes`
          0% {
            margin-letf: 0; 
          }
          50%{
            margin-left: -${lengthButtons}px
          }
          100%{
            margin-left: 0
          }
`;
  var ListButtonsSlider = styled.div`
    display: flex;
    animation: ${scrollAuto
      ? css`
          ${efect} ${delayTransition}s linear infinite
        `
      : `none`};
    margin-left: ${scrollX};
    &:hover {
      animation-play-state: paused;
    }
  `;

  const showSidebar = () => {
    return (
      <div className="div-sier-new">
        {jsonMenu.map((button, index) => (
          <ul key={index} className="n-nav">
            {button.link !== undefined ? (
              <a href={button.link} className="link-sidr">
                {button.name}
              </a>
            ) : (
              <h6>{button.name}</h6>
            )}
            {button.sub_link !== undefined ? dropDow(button.sub_link) : ""}
          </ul>
        ))}
      </div>
    );
  };

  function sumList02(array, index) {
    //vai aplicar o jsx

    if (array.sub_link !== undefined) {
      /*vai verificar se existe uma lista dentro de outra lista
  <ul>
  <li></li>
  <li></li>
  <li><ul></ul></li>
  </ul>
    */

      return (
        <>
          <li className={styled.listItemPrimary} key={index}>
            {checkLink02(array)}
            <ul className={styled.subList}>
              {array.sub_link.map((itens, index) => sumList02(itens, index))}
            </ul>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className={styled.subItem} key={array.name}>
            <a href={array.link} className={styled.link}>
              {array.name}
            </a>
          </li>
        </>
      );
    }
  }
  //vai verificar se existe um link (o elemento pai de uma lista oculta não deve possuir link)
  function checkLink02(array) {
    if (array.link !== undefined) {
      return (
        <Nav.Link href={array.link} className="drop-navba" key={array.name}>
          <p>{array.name}</p>
        </Nav.Link>
      );
    } else {
      return <p className={styled.textTopList}>{array.name}</p>;
    }
  }

  const dropDow = (array) => {
    return (
      <div className="">
        {array.map((b, index) => (
          <li key={index} className="list">
            {b.link !== undefined ? (
              <Nav.Link href={b.link} className="Link-drop">
                {b.name}
              </Nav.Link>
            ) : (
              <h6 className="">{b.name}</h6>
            )}
            <ul>{b.sub_link !== undefined ? dropDow(b.sub_link) : ""}</ul>
          </li>
        ))}
      </div>
    );
  };

  return (
    <>
      <section className={styledCSS.navbarCM}>
        <div className={styledCSS.navbarColumn1}>
          <a href="/" className={styledCSS.cardLogo}>
            <img
              alt="Logo Portal"
              src="/imagens/logo.jpg"
              className={styledCSS.navLogoCM}
            />
            <div className={styledCSS.navNameCM}>
              <h3 className={styledCSS.titleNavbarCM}>{NomePortal}</h3>
              <hr className={styledCSS.line} />
              <h5 className={styledCSS.titleNavbarCM}>{NomeTipoPortal}</h5>
            </div>
          </a>
        </div>
        <div className={styledCSS.navbarColumn2}>
          <div className={styledCSS.subColumn}>
            <div className={styledCSS.groupAcessibilidade}>
              <form className={styledCSS.cardSearch}>
                <input
                  type="text"
                  placeholder="Buscar"
                  name="search-main"
                  className={styledCSS.inputSearch}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button
                  className={styledCSS.buttonSearch}
                  onClick={(e) => selectOpen(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="currentColor"
                    className={styledCSS.iconSearch}
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </button>
              </form>

              <div className={styledCSS.cardButtonAcess}>
                {/* Acessibilidade */}
                <a
                  className={styledCSS.iconeAcessibilidade}
                  title="Acessibilidade"
                  href="/acessibilidade"
                >
                  <svg
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    width="502.483px"
                    height="502.483px"
                    viewBox="0 0 502.483 502.483"
                  >
                    <g>
                      <g>
                        <path
                          d="M296.64,397.279c-11.139,21.032-26.836,37.736-47.109,50.11c-20.269,12.364-42.395,18.556-66.379,18.556
                              c-35.214,0-65.334-12.509-90.364-37.542c-25.028-25.026-37.543-55.151-37.543-90.365c0-25.313,6.945-48.577,20.841-69.806
                              c13.894-21.222,32.453-36.974,55.672-47.251l-4.853-37.402c-32.358,11.803-58.479,31.882-78.371,60.242
                              c-19.89,28.361-29.834,59.77-29.834,94.217c0,29.701,7.376,57.153,22.126,82.371s34.735,45.204,59.954,59.953
                              c25.216,14.749,52.678,22.121,82.368,22.121c35.594,0,67.858-10.562,96.785-31.689c28.932-21.122,48.917-48.725,59.957-82.796
                              l-29.119-58.241C312.484,353.742,307.775,376.246,296.64,397.279z"
                        />
                        <path
                          d="M467.228,358.307L410.7,386.574l-65.096-129.904c-3.237-6.66-8.665-9.992-16.276-9.992H199.421l-4.568-36.543h116.205
                              v-36.547H190.29l-10.566-82.512c12.946,1.331,24.366-2.618,34.263-11.848c9.898-9.231,14.847-20.413,14.847-33.547
                              c0-12.562-4.471-23.317-13.418-32.264C206.466,4.471,195.71,0,183.151,0c-10.277,0-19.511,2.996-27.694,8.992
                              c-8.186,5.996-13.61,13.844-16.274,23.551c-1.525,4.952-2.096,8.947-1.713,11.993l27.406,222.41
                              c0.572,4.575,2.615,8.422,6.136,11.57c3.521,3.135,7.566,4.709,12.135,4.709h134.758l68.245,136.188
                              c3.237,6.655,8.657,9.992,16.279,9.992c3.045,0,5.8-0.664,8.278-1.998l73.076-36.549L467.228,358.307z"
                        />
                      </g>
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </a>
                {/*Libras */}
                <div
                  className={styledCSS.iconeAcessibilidade}
                  title="Libras"
                  onClick={() => {
                    document.getElementsByClassName("access-button")[0].click();
                  }}
                >
                  <svg
                    version="1.1"
                    id="Icons"
                    x="0px"
                    y="0px"
                    viewBox="0 0 32 32"
                  >
                    <path
                      className="st2"
                      d="M15,17V7c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v13l-3-5.2c-0.6-1-1.8-1.3-2.8-0.6l0,0c-0.7,0.5-1.1,1.4-0.7,2.3
                          l0,0c1.6,4.3,4,8.4,7,11.9"
                    />
                    <path
                      className="st2"
                      d="M15,17V5c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v12"
                    />
                    <path
                      className="st2"
                      d="M19,17V7c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v10"
                    />
                    <path
                      className="st2"
                      d="M23,17V9c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2v8v0c0,4-0.8,7.9-2.4,11.5"
                    />
                  </svg>
                </div>
                {/* Contraste */}
                <div
                  className={styledCSS.iconeAcessibilidade}
                  title="Contraste"
                  onClick={() => {
                    clickSegment();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M5.636 6.636L12 .272l6.364 6.364a9 9 0 1 1-12.728 0zM7.05 8.05A7 7 0 0 0 12.004 20L12 3.1 7.05 8.05z"
                      className={styledCSS.contrastSvg}
                    />
                  </svg>
                </div>
                {/* Diminuir a letra */}
                <div
                  className={styledCSS.iconeAcessibilidade}
                  id="decrease-font"
                  title="Diminuir Texto"
                  accessKey="-"
                  onClick={() => setDecrease(decrease + 1)}
                >
                  <svg
                    version="1.1"
                    id="icon-subtraction"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512"
                  >
                    <rect x="1" y="229.3" width="512" height="85.3" />
                  </svg>
                </div>
                {/* Letra no tamanho normal */}
                <div
                  className={styledCSS.iconeAcessibilidade}
                  id="normal-font"
                  title="Texto no tamanho normal"
                  onClick={() => setFontNormal(fontNormal + 1)}
                >
                  <svg
                    version="1.1"
                    id="letter-a"
                    x="0px"
                    y="0px"
                    width="475.082px"
                    height="475.082px"
                    viewBox="0 0 475.082 475.082"
                  >
                    <g>
                      <path
                        d="M473.371,433.11c-10.657-3.997-20.458-6.563-29.407-7.706c-8.945-0.767-15.516-2.95-19.701-6.567
                        c-2.475-1.529-5.808-6.95-9.996-16.279c-7.806-15.604-13.989-29.786-18.555-42.537c-7.427-20.181-13.617-35.789-18.565-46.829
                        c-10.845-25.311-19.982-47.678-27.401-67.092c-4.001-10.466-15.797-38.731-35.405-84.796L255.813,24.265l-3.142-5.996h-15.129
                        h-21.414l-79.94,206.704L68.523,400.847c-5.33,9.896-9.9,16.372-13.706,19.417c-3.996,2.848-14.466,5.805-31.405,8.843
                        c-11.042,2.102-18.654,3.812-22.841,5.141L0,456.812h5.996c16.37,0,32.264-1.334,47.679-3.997l13.706-2.279
                        c53.868,3.806,87.082,5.708,99.642,5.708c0.381-1.902,0.571-4.476,0.571-7.706c0-5.715-0.094-11.231-0.287-16.563
                        c-3.996-0.568-7.851-1.143-11.561-1.711c-3.711-0.575-6.567-1.047-8.565-1.431c-1.997-0.373-3.284-0.568-3.855-0.568
                        c-14.657-2.094-24.46-5.14-29.407-9.134c-3.236-2.282-4.854-6.375-4.854-12.278c0-3.806,2.19-11.796,6.567-23.982
                        c14.277-39.776,24.172-65.856,29.692-78.224l128.483,0.568l26.269,65.096l13.411,32.541c1.144,3.241,1.711,6.283,1.711,9.138
                        s-1.14,5.428-3.426,7.707c-2.285,1.905-8.753,4.093-19.417,6.563l-37.404,7.994c-0.763,6.283-1.136,13.702-1.136,22.271
                        l16.56-0.575l57.103-3.138c10.656-0.38,23.51-0.575,38.547-0.575c18.264,0,36.251,0.763,53.957,2.282
                        c21.313,1.523,39.588,2.283,54.819,2.283c0.192-2.283,0.281-4.754,0.281-7.423C475.082,445.957,474.513,440.537,473.371,433.11z
                        M251.245,270.941c-2.666,0-7.662-0.052-14.989-0.144c-7.327-0.089-18.649-0.233-33.973-0.425
                        c-15.321-0.195-29.93-0.383-43.824-0.574l48.535-128.477c7.424,15.037,16.178,35.117,26.264,60.242
                        c11.425,27.79,20.179,50.727,26.273,68.809L251.245,270.941z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </svg>
                </div>
                {/* aumentar letra */}
                <div
                  className={styledCSS.iconeAcessibilidade}
                  id="increase-font"
                  title="Aumentar Texto"
                  accessKey="="
                  onClick={() => setIncrease(increase + 1)}
                >
                  <svg
                    width="21px"
                    height="20px"
                    viewBox="0 0 21 20"
                    version="1.1"
                  >
                    <g
                      id="plus-icon-navbar"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-379.000000, -240.000000)"
                        fill="#000000"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                          className={styledCSS.contrastSvg}
                        >
                          <polygon
                            id="plus-[#1512]"
                            points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89"
                          ></polygon>
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {/* mapa do site */}
                <a
                  className={styledCSS.iconeAcessibilidade}
                  title="Mapa Do Site"
                  href="/mapa-site"
                >
                  <svg className="bi bi-map button-svg" viewBox="0 0 16 16">
                    <path
                      fillRule="evenodd"
                      d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className={styledCSS.groupMenu}>
              <div className="buttons">
                <div className={styledCSS.containerMenuSlider}>
                  <div className="arrow-slider-menu" onClick={handleRightArrow}>
                    <svg width="24" height="24">
                      <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
                    </svg>
                  </div>
                  <div
                    className="arrow-slider-menu arrow-left"
                    onClick={handleLeftArrow}
                  >
                    <svg width="24" height="24">
                      <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
                    </svg>
                  </div>
                  <div className="navbar-button-list">
                    <ListButtonsSlider
                      className="list-navbar lista-primaria-navbar"
                      id="ancoraMenu"
                      style={{ marginLeft: scrollX }}
                    >
                      {arrayMap}
                    </ListButtonsSlider>
                  </div>
                </div>
              </div>
            </div>
            <svg
              width="16"
              height="16"
              onClick={() => {
                setShow(true);
              }}
              className={styledCSS.menuDrow}
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
        </div>
      </section>

      <Offcanvas show={show} onHide={handleCloseDrop} responsive="lg">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`}>
            Menu
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{statusNavbarleft ? "" : showSidebar()}</Offcanvas.Body>
      </Offcanvas>
      {/* : ""} */}

      <JoinButtonsTopNavbar />
    </>
  );
}
