import { Button } from "react-bootstrap";
import styled from "../../../styles/module/others/notFound.module.css";

export default function NotFound() {
 
  return (

    //css em global
    <div className={styled.card}>
    <div className={styled.txt}>
    <div className={styled.boxImg}>
    <img className={styled.img} src="/imagens/logo-work.png" alt="" />         
    <img className={styled.img} src="/imagens/logo.jpg" alt="" />         
    </div>
    <div className={styled.bodyTxt}>

    <h1>404</h1>
    <h1>Essa página não foi encontrada</h1>
    <br/>
    <Button href="/">Voltar a Página Principal</Button>
    </div>
    </div>
    </div>
  )
}

