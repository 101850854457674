import NavbarCM from "../others/NavbarCM";
import Navbar from "../others/Navbar";
import Footer from "../others/Footer";
import { TipoPortal } from "../../../services/variables";
import "./styles.css";

export default function RedesSociais() {
  return (
    <main>
      {TipoPortal === 4 ? <NavbarCM /> : <Navbar />}
      <br />
      <a
        href="https://www.planalto.gov.br/ccivil_03/leis/l9504.htm"
        target="_blank"
        rel="noreferrer"
      >
        <picture>
          <source
            media="(min-width: 600px)"
            srcset="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/483bcd1babe83af2d8e22d0a0b2acc87b495d941_c327e5a1-9312-4020-980e-0ba87491cad4.png"
          />
          <img
            src="https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/483bcd1babe83af2d8e22d0a0b2acc87b495d941_c327e5a1-9312-4020-980e-0ba87491cad4.png"
            alt="infor"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "15px",
              objectFit: "cover",
            }}
          />
        </picture>
      </a>
      <br />
      <Footer />
    </main>
  );
}
